import { Component, Inject } from '@angular/core';
import {  MatLegacyDialogRef as MatDialogRef,  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {  UntypedFormGroup } from '@angular/forms';
import { AlarmService } from '../alarms/alarm.service';
import {  MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import {  MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'alarm-teams-popup',
  templateUrl: 'alarm-teams-popup.html',
  styleUrls: ['alarm-teams-popup.css']

})
export class AlarmTeamsPopup {
  formGroup: UntypedFormGroup;
  showSpinner:boolean=false;
  teamsChannelName:string="";
  teamsChannelURL:string=""
  isInputHasValue:boolean=false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public alarmService: AlarmService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AlarmTeamsPopup>) {
      let plant_name=this.data.Plant;      
  }
  ngOnInit(){
    this.teamsChannelName = this.data['data']['TeamChannel']
    this.teamsChannelURL = this.data['data']['TeamURL']
  }

  validateChannel(){
    this.showSpinner=true;
    let outJson={};
    outJson['TeamChannel']=this.teamsChannelName;
    outJson['TeamURL']=this.teamsChannelURL;
    this.alarmService.validateTeamsChannel(outJson).subscribe(resp => {      
      this.showSpinner=false;
      if(resp['Body']!="200" && resp['Body']!="202"){
        let error_message_json=resp['ErrorMessage'].replace('"\"', "");
        error_message_json=JSON.parse(error_message_json);
        let error_message=error_message_json['error']['message'];
        this._snackBar.open(error_message, "Error", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }else{
        outJson['eventype']="update";
        this.dialogRef.close({event:true,data:outJson});
      }
    });

  }

  onCancelClick():void{
    this.dialogRef.close({event:true,data:{"eventype":"close"}});
  }

  inputChange(){
    if(this.teamsChannelName && this.teamsChannelURL)
    { 
      this.isInputHasValue=true;
    }else{
      this.isInputHasValue=false;
    }
  }
  openHelpDocument() : void{
    window.open('/helpDocument','_blank');
  }

}
