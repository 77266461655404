<div class="help-doc-container">
<div class="page-heading"><b>Help Document For Configuring A Teams Channel For Alarms</b></div>
<div class="step-div">
    <div class="step-number">Step 1</div>
    <div class="step-content">
        Open Microsoft Teams application and click on "Teams" option and choose "See all channels", 
        Then use either existing channel or create new channel to link channel with a user group.<br/><br/>
        <h5><b>New Channel</b></h5>
         Click on "+" sign and Add channel. After creating, proceed to step-2<br/><br/>
        <h5><b>Existing Channel</b></h5>
        If you want to use existing channel, directly proceed to step-2
    </div>
    <div class="step-images">
        <img src="../../assets/images/help-document-images-latest/image1.png" width="100" height="100">
    </div>
</div>

<div class="step-div">
    <div class="step-number">Step 2</div>
    <div class="step-content">Select & right click on teams channel and then click on Manage Channel <br/><br/> 
        Select Edit
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image2.png" width="100" height="100">
    </div>
</div>

<div class="step-div">
    <div class="step-number">Step 3</div>
    <div class="step-content">Under Channel click on … & then select WorkFlows 
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image-step3-new.png" width="100" height="100">
    </div>
</div>


<div class="step-div">
    <div class="step-number">Step 4</div>
    <div class="step-content"> Select "Post to a channel when a webhook request is received"  Template under Notify Team
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image-step4-new.png" width="150" height="50">
    </div>
</div>

<div class="step-div">
    <div class="step-number">Step 5</div>
    <div class="step-content"> Give the Connection name & press "Next". 
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image-step5-new.png" width="150" height="50">
    </div>
</div>

<div class="step-div">
    <div class="step-number">Step 6</div>
    <div class="step-content"> Validate Team and Channel are correct then click Add workflow and Copy URL.
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image-step6-new.png" width="150" height="50">
    </div>
</div>


<div class="step-div">
    <div class="step-number">Step 7</div>
    <div class="step-content"> Open Brilliant Factory Alarm Application.<br/>Create or Select group.<br/>
        Click the Check box for Link Teams Channel.
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image6.png" width="150" height="50">
    </div>
</div>

<div class="step-div">
    <div class="step-number">Step 8</div>
    <div class="step-content"> Enter the Name of the Channel(need not be match with teams channel name) and copy the URL from the teams web hook configuration.<br/><br/>
        Click Submit
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image7.png" width="150" height="50">
    </div>
</div>

<div class="step-div">
    <div class="step-number"></div>
    <div class="step-content"> This should send a TEST Message and Report success at the top of the screen.
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image8.png" width="150" height="50">
    </div>
</div>

<div class="step-div">
    <div class="step-number"></div>
    <div class="step-content"> Open Microsoft Teams Application and go the channel. You can find the test message.
    </div>
    <div class="step-images"> 
        <img src="../../assets/images/help-document-images-latest/image9.png" width="150" height="50">
    </div>
</div>


</div>